

















































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { formatarMoeda } from '@/shareds/formatadores'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import mapErrosSefaz from '@/shareds/fiscal/tabelas/mapErrosSefaz'
import {
	obterCnpjOuCpfDaVenda,
	obterDisplayClienteDaVenda,
} from '@/shareds/venda-shareds'
import { NotaDaVenda, ResumoDaVenda } from '@/models'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'


@Component({})
export default class GridDeVenda extends Vue{
	@Prop({ type: Array, default: () => [] }) vendas!: ResumoDaVenda[]
	@Prop({ type: Object }) notas!: NotaDaVenda
	mapErrosSefaz = mapErrosSefaz
	formatarMoeda = formatarMoeda
	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	obterCnpjOuCpfDaVenda = obterCnpjOuCpfDaVenda
	obterDisplayClienteDaVenda = obterDisplayClienteDaVenda

	headers = [
		{ text: 'Identificador', value: 'identificador' },
		{ text: 'Loja', value: 'loja', sortable: false },
		{ text: 'PDV', value: 'pontoDeVenda.nome', sortable: false },
		{ text: 'Data e Hora', value: 'dataHora' },
		{ text: 'Cliente', value: 'displayCliente', sortable: false },
		{ text: 'Tipo de Cliente', value: 'displayTipoDeCliente' , sortable: false },
		{ text: 'NF / Série', value: 'displayNfSerie', sortable: false },
		{ text: 'Último Status', value:'displayStatusDaNota', sortable: false },
		{ text: 'Total (R$)',  value:'displayTotalDaVenda', sortable: false },
		{ text: 'Ações', value: 'actions', sortable: false, align: 'center' },
	]

	get itensFormatados() {
		return this.vendas.filter(venda => venda.notas.length > 0)
			.map(venda => ({
				...venda,
				displayNfSerie: this.displayNfSerie(venda),
				displayCliente: obterDisplayClienteDaVenda(venda),
				displayTotalDaVenda: this.displayTotalDaVenda(venda),
				displayStatusDaNota: this.displayStatusDaNota(venda),
				numeroDaNota: venda.notas[0] ? venda.notas[0].nnf : null,
				serieFiscal: venda.notas[0] ? venda.notas[0].serie : null,
			}))
	}

	displayTipoDeCliente(venda: ResumoDaVenda) {
		if(venda.cliente) {
			return venda.cliente.tipoDeClienteNome
		}
		return '---'
	}

	get computedHeaders() {
		return this.headers.filter(({ value }) => {
			if (value === 'displayTotalDaVenda') {
				return !this.ocultarColunaDeTotalDaVenda;
			} 
			if (value === 'displayTipoDeCliente') {
				return this.exibirColunaDeTipoDeCliente;
			}
			return true
		});
	}

	get exibirColunaDeTipoDeCliente() {
		return UserLoginStore.permiteRegraDeNegocio('exibir-tipo-de-cliente-tela-de-vendas')
	}
	
	get ocultarColunaDeTotalDaVenda() {
		return UserLoginStore.permiteRegraDeNegocio('ocultar-valor-das-vendas')
	}

	tipoDeTransacaoFormatada(item) {
		return `${item.tipoDeTransacao} ${item.tipoNota || ''}`
	}

	displayTotalDaVenda(venda: ResumoDaVenda) {
		if (!venda.total) return '0,00'
		return formatarMoeda(venda.total)
	}

	displayStatusDaNota(venda: ResumoDaVenda) {
		const nota = venda.notas[0]
		return nota && mapErrosSefaz[nota.cstat]
			? nota.cstat + ' - ' + mapErrosSefaz[nota.cstat]
			: nota?.cstat || ''
	}

	displayNfSerie(venda: ResumoDaVenda) {
		const nota = venda.notas[0]
		return nota
			? `${nota.modelo} ${nota.nnf} / ${nota.serie}`
			: ''
	}
}

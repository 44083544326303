






















































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { CancelarVendaUseCase, EditarUmaVendaUseCase, FindLojaUseCase } from '@/usecases'
import { FiltroDeVenda, NotaDaVenda, ResumoDaVenda, MotivosCancelamentoFiscal } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { formatarMoeda, removerFormatacaoDeCnpjOuCpf } from '@/shareds/formatadores'
import GridDeNotas from '@/views/application/venda/GridDeNotas.vue'
import { DataOptions } from 'vuetify'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import DialogoDeDetalhesDaVenda from '@/components/venda/DialogoDeDetalhesDaVenda.vue'
import { obterDisplayClienteDaVenda, obterTotalDaVenda } from '@/shareds/venda-shareds'
import mapErrosSefaz from '@/shareds/fiscal/tabelas/mapErrosSefaz'
import moment from 'moment'
import { FindVendaUseCase } from '@/usecases'
import SelecaoDePdv from '@/components/venda/SelecaoDePdv.vue'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import CamposDeFiltrosDeVendas from '@/views/application/venda/CamposDeFiltrosDeVendas.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { nextTick } from '@/shareds/utils'
import { sortConverter } from '@/shareds/paginacao'
import { CancelarNotaFiscalUseCase } from '@/usecases/fiscal/CancelarNotaFiscalUseCase'
import RodapePersonalizado from '@/components/ui/data-tables/RodapePersonalizado.vue'
import DialogoDeInformarEmail from './DialogoDeInformarEmail.vue'
import ConfirmacaoComMotivoFiscal from '@/components/ui/ConfirmacaoComMotivoFiscal.vue'

const FILTRO_DE_VENDAS = 'FILTRO_DE_VENDAS'

@Component ({
	components: {
		Confirmacao,
		GridDeNotas,
		DialogoDeDetalhesDaVenda,
		CamposDeFiltrosDeVendas,
		SeletorDeLojasDoUsuario,
		SelecaoDePdv,
		RodapePersonalizado,
		DialogoDeInformarEmail,
		ConfirmacaoComMotivoFiscal,
	},
})
export default class TelaDeListagemDeNotas extends Vue {
	@Ref() dialogoDeFiltros!: CamposDeFiltrosDeVendas
	@Ref() form!: HTMLFormElement
	@Ref() dialogoDeInformarEmail!: DialogoDeInformarEmail
	buscando = false
	vendas: ResumoDaVenda[] = []
	findUseCase = new FindVendaUseCase()
	editarUmaVendaUseCase = new EditarUmaVendaUseCase()

	findLojaUseCase = new FindLojaUseCase()
	cancelarVendaUseCase = new CancelarVendaUseCase()
	cancelarNotaFiscalUseCase = new CancelarNotaFiscalUseCase()
	motivoDoCancelamentoFiscal: MotivosCancelamentoFiscal | null = null
	filtro: FiltroDeVenda = localStorage[FILTRO_DE_VENDAS]
		? JSON.parse(localStorage[FILTRO_DE_VENDAS])
		: {
			busca: '',
			loja: '',
			datas: [],
			horas: [null, null],
			cliente: '',
			exibeVendasComErros: false,
			ambientes: ['Produção', 'Homologação'],
			vendasComOrcamento: false,
		}
	totalRegistros = -1
	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	podeVisualizar: boolean | null = null

	cancelando: string[] = []
	cancelandoNotasFiscais: string[] = []
	enviandoEmail: string[] = []

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	get podeCancelarVenda() {
		return UserLoginStore.permiteRegraDeNegocio('pode-cancelar-venda')
	}

	get podeCancelarNota() {
		return UserLoginStore.permiteRegraDeNegocio('pode-cancelar-nota-fiscal')
	}

	get podeEnviarPorEmail() {
		return UserLoginStore.permiteRegraDeNegocio('pode-enviar-notas-por-email')
	}

	created(){
		return this.buscar()
	}

	displayNfSerie (venda: ResumoDaVenda ){
		const nota = venda.notas[0]
		return nota
			? nota.nnf + ' / ' + nota.serie
			: ''
	}

	displayTotalDaVenda(venda: ResumoDaVenda){
		return formatarMoeda(venda.total)
	}

	displayStatusDaNota(venda: ResumoDaVenda) {
		const nota = venda.notas[0]
		return nota && mapErrosSefaz[nota.cstat]
			? nota.cstat + ' - ' + mapErrosSefaz[nota.cstat]
			: nota?.cstat || ''
	}

	displayNotasFiscais(venda: ResumoDaVenda): string {
		return venda.notas
			.filter(({ cstat }) => !['101', '102'].includes(cstat))
			.map(nota =>
				`Nota ${nota.nnf} / ${nota.serie}`,
			).join('<br>')
	}

	get itensFormatados() {
		return this.vendas.map(venda => ({
			...venda,
			displayNfSerie: this.displayNfSerie(venda),
			displayCliente: obterDisplayClienteDaVenda(venda),
			displayTotalDaVenda: this.displayTotalDaVenda(venda),
			displayStatusDaNota: this.displayStatusDaNota(venda),
			numeroDaNota: venda.notas[0] ? venda.notas[0].nnf : null,
			serieFiscal: venda.notas[0] ? venda.notas[0].serie : null,
		}))
	}

	podeCancelar(notas: NotaDaVenda[]) {
		return !notas.length || notas.some(({ cstat }) => cstat !== '101' && cstat !== '102')
	}

	async cancelarVenda(venda: ResumoDaVenda) {
		if (!venda) {
			AlertModule.setError('Venda a cancelar não encontrada')
			return
		}
		try {
			if (this.cancelando.includes(venda.id)) throw new Error('Venda já está em cancelamento')

			this.cancelando.push(venda.id)
			const vendaAtualizada = await this.cancelarVendaUseCase.cancelar(venda.id)

			const vendaResumida = {
				...vendaAtualizada,
				total: obterTotalDaVenda(vendaAtualizada),
				vendaOrigem: undefined,
				chaveDanfeExterna: null,
			} as ResumoDaVenda
			this.vendas = this.vendas.map(venda => venda.id === vendaAtualizada.id
				? vendaResumida
				: venda,
			)
			this.cancelando = this.cancelando.filter(id => venda.id !== id)
			AlertModule.setSuccess(`Venda ${venda.identificador} cancelada com sucesso`)
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	async cancelarNotasFiscais(
		venda: ResumoDaVenda, 
		motivoDoCancelamentoFiscal: string,
	) {

		if (!venda.notas.length) {
			AlertModule.setError('Venda não possui notas fiscais emitidas')
			return
		}

		this.cancelandoNotasFiscais.push(venda.id)
		if (!motivoDoCancelamentoFiscal) {
			AlertModule.setError('Necessário um motivo para cancelamento')
			return
		}

		try {
			const vendaAtualizada = await this.cancelarNotaFiscalUseCase.execute(
				venda.id,
				motivoDoCancelamentoFiscal,
			)

			const vendaResumida = {
				...vendaAtualizada,
				total: obterTotalDaVenda(vendaAtualizada),
				vendaOrigem: undefined,
				chaveDanfeExterna: null,
			} as ResumoDaVenda
			this.vendas = this.vendas.map(venda =>
				venda.id === vendaAtualizada.id ? vendaResumida : venda,
			)
			const notasList = vendaAtualizada.notas
				.map(nota => `Nota ${nota.nnf} / ${nota.serie} cancelada com sucesso`)
				.join('<br>')
			AlertModule.setSuccess({
				text: notasList,
				timeout: -1,
			})

			this.cancelandoNotasFiscais = this.cancelandoNotasFiscais.filter(id => venda.id !== id)

		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.cancelandoNotasFiscais = this.cancelandoNotasFiscais.filter(
				id => venda.id !== id,
			)
			this.buscar()
		}		
	}


	gerarDataHora(data?: string | null, hora?: string | null): string | undefined {
		return moment(`${data} ${hora}`,  'YYYY-MM-DD HH:mm').toISOString(true)
	}

	aplicarFiltros() {
		this.buscar()
	}

	async buscar() {
		await nextTick()
		try {
			this.buscando = true
			const dataHoraInicial = this.gerarDataHora(this.filtro.datas[0], this.filtro.horas[0] || '00:00')
			const dataHoraFinal = this.gerarDataHora(this.filtro.datas[1], this.filtro.horas[1] || '23:59')
			const sorted = sortConverter(this.paginacao)
			const page = await this.findUseCase.listNota({
				dataHoraInicial,
				dataHoraFinal,
				lojaId: this.filtro.lojaId || undefined,
				cliente: removerFormatacaoDeCnpjOuCpf((
					typeof this.filtro.cliente === 'string'
						? this.filtro.cliente
						: this.filtro.cliente?.cnpjOuCpf) || null,
				) || undefined,
				numeroNota: this.filtro.numeroDaNota || undefined,
				serie: this.filtro.serieFiscal || undefined,
				pdvId: this.filtro.pdvId || undefined,
				exibeVendasComErros: this.filtro.exibeVendasComErros,
				ambientes: this.filtro.ambientes.join(',') || undefined,
				identificador: this.filtro.identificador || undefined,
				vendasComOrcamento: this.filtro.vendasComOrcamento || undefined,
				page: this.paginacao.page,
				size: this.paginacao.itemsPerPage,
				sort: !sorted.length ? 'dataHora,desc' : sorted,
			})
			this.vendas = page.content
			this.totalRegistros = page.totalElements

		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.buscando = false
			this.dialogoDeFiltros.ocultar()
		}
	}

	async limparSelecaoDeFiltros() {
		try {
			this.buscando = true
			const page = await this.findUseCase.listNota({
				page: this.paginacao.page -1,
				size: this.paginacao.itemsPerPage,
				sort: 'dataHora,desc',
			})

			this.vendas = page.content
			this.totalRegistros = page.totalElements

		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.buscando = false
			this.dialogoDeFiltros.ocultar()
		}
	}

	abrirFiltros() {
		this.dialogoDeFiltros.mostrar()
	}

	@Watch('filtro', { deep: true })
	onChangeFiltro(newFiltro) {
		if(this.filtro.lojaId == null){
			this.filtro.pdvId = ''
		}
		this.filtro.datas[0] = this.filtro.datas[0] !== null && this.filtro.datas[1] === null
			? null
			: this.filtro.datas[0]
		const stringified = JSON.stringify(newFiltro);
		localStorage.setItem(FILTRO_DE_VENDAS, stringified)
	}

	atualizarVenda(vendaAtualizada: ResumoDaVenda) {
		const indiceDaVenda = this.itensFormatados.findIndex(
			({ id }) => id === vendaAtualizada.id,
		)
		this.vendas.splice(indiceDaVenda, 1, vendaAtualizada)
	}

	navegar(page) {
		this.paginacao.page += page
		this.buscar()
	}

	async enviarPorEmail(venda: ResumoDaVenda) {
		try {
			if (this.enviandoEmail.includes(venda.id)) throw new Error('Está venda já tem um processo de envio em andamento')

			if (venda.cliente) {
				if (venda.cliente.email) {
					this.enviandoEmail.push(venda.id)
					await this.editarUmaVendaUseCase.enviarEmail(venda.id, venda.cliente.email)
				} else {
					this.dialogoDeInformarEmail.mostrar(venda)
				}
			} else {
				this.dialogoDeInformarEmail.mostrar(venda)
			}
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.enviandoEmail = this.enviandoEmail.filter(id => venda.id !== id)
		}
	}

	temNotaParaEnviarEmail(venda: ResumoDaVenda) {
		return venda.notas.filter((nota: NotaDaVenda) => nota.cstat === '100').length > 0
	}

	bloquearEmissaoDeNovaNota(venda: ResumoDaVenda): boolean {
		if (venda.notas.some(nota => ['101', '102'].includes(nota.cstat))) { 
			return true; 
		}
		return false;
	}
}
